<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li @click="$router.go(-1)">
					<el-button size="mini"><i class="icon iconfont icon-fanhui"></i>返回上一步</el-button>
				</li>
				<li>
					<el-button size="mini" @click="getList">刷新</el-button>
				</li>
				<li v-if="$store.state.villageId>0">
					<el-button @click="alldele" class="el-white" size="mini" type="danger">删除</el-button>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.keyword" placeholder="请输入名称">
					</el-input>
				</li>
				<li>
					<el-select clearable v-model="form.status" placeholder="请选择状态">
						<el-option label="全部" value=""></el-option>
						<el-option label="已签到" :value="1"></el-option>
						<el-option label="未签到" :value="0"></el-option>
					</el-select>
				</li>
				<li>
					<el-select clearable v-model="form.is_photos" placeholder="是否已拍照上传">
						<el-option label="全部" value=""></el-option>
						<el-option label="已拍照上传" :value="1"></el-option>
						<el-option label="未拍照上传" :value="0"></el-option>
					</el-select>
				</li>
				<li>
					<el-button @click="search" color="#00ad79" class="el-white" style="color: white" size="mini">搜索
					</el-button>
				</li>
				<li>
					<el-button @click="reset" size="mini" class="el-white" type="danger">清空</el-button>
				</li>
			</ul>
		</div>
		<!-- 表格列表 -->
		<div class="table">
			<el-table element-loading-text="加载中..." v-loading="tableloading" :max-height="maxheight" border
				:data="tableData" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="80" />
				<el-table-column type="index" label="序号" width="80" />
				<el-table-column property="date" label="头像">
					<template #default="scope">
						<el-avatar :size="30" :src="scope.row.avatar">
							<img src="../../../assets/img/tx.png" />
						</el-avatar>
					</template>
				</el-table-column>
				<el-table-column property="party_member_name" label="姓名" />
				<el-table-column property="tel" label="手机号码" />
				<el-table-column property="idcard" label="身份证" />
				<el-table-column property="party_member_name" label="上传图片">
					<template #default="scope">
						<div class="dialog-up">
							<div v-if="scope.row.imgpath" style="cursor: pointer;"
								@click="seeImg(ImgList(scope.row.imgpath))"
								v-for="(item,index) in ImgList(scope.row.imgpath).slice(0,1)" :key="index">
								<el-image class="img" :src="item" fit="fill">
								</el-image>
								<p>共{{ImgList(scope.row.imgpath).length}}张点击查看</p>
							</div>
							<div class="zanwu" v-else>暂无</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column property="party_member_integral" label="总积分" />
				<el-table-column property="integral" label="积分" />
				<el-table-column property="explains" label="备注说明" />
				<el-table-column property="signin_time" label="签到时间" />
				<el-table-column property="signin_time" label="拍照上传时间" />
				<el-table-column property="status" label="状态" >
				</el-table-column>
				<el-table-column property="address" label="操作" v-if="$store.state.villageId>0" width="300">
					<template  #default="scope">
						<el-button @click="integral(scope.row.id,'增加积分')" size="mini">增加积分
						</el-button>
						<el-button @click="integral(scope.row.id,'减少积分')" size="mini">减少积分
						</el-button>
						<el-button size="mini" @click="dele(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页 -->
			<div class="pages">
				<el-pagination background v-model="form.page" :page-sizes="[15, 20, 30, 40,50,100]"
					:page-size="form.limit" layout="sizes, prev, pager, next, jumper" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
		<!-- 全部图片 -->
		<seeImg ref="seeImg"></seeImg>
		<!-- 积分 -->
		<integral ref="integral" @SubmitForm="getList"></integral>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/signup";
	import seeImg from '@/components/seeImg.vue'
	import integral from './integral.vue'
	export default {
		components: {
			seeImg,
			integral
		},
		data() {
			return {
				maxheight: document.documentElement.clientHeight - 232 + 'px',
				total: 0,
				form: {
					keyword: '',
					page: 1,
					limit: 15,
					status: "",
					activity_id: '',
					is_photos: ''
				},
				tableData: [],
				idlist: [],
			}
		},
		created() {
			this.form.activity_id = this.$route.query.id
			this.getList()
		},
		methods: {
			// 积分
			integral(id, val) {
				this.$refs.integral.show(id, val)
			},
			// 查看图片
			seeImg(list) {
				this.$refs.seeImg.show(list)
			},
			// 图片转化
			ImgList(val) {
				if (val) {
					return val.split(",")
				} else {
					return []
				}
			},
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 清空
			reset() {
				for (let key in this.form) {
					this.form[key] = ''
				}
				this.form.limit = 15
				this.form.page = 1
				this.form.status = ""
				this.form.activity_id = this.$route.query.id
				this.getList()
			},
			// 搜索
			search() {
				this.form.page = 1
				this.getList()
			},
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(item.id)
				})
			},
			// 获取列表
			getList() {
				this.tableloading = true
				this.$http.post(api.lists, this.form)
					.then((res) => {
						if (res.code == 200) {
							this.tableData = res.data.data
							this.total = res.data.count
							this.tableloading = false
						}
					})
			},
			// 新增
			bindfrom(val, id) {
				this.$refs.form.show(val, id)
			},
			// 批量删除
			alldele() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要删除数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'此操作将永久删除?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.dele, {
									id: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 200) {
										ElMessage({
											type: 'success',
											message: '删除成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 删除
			dele(id) {
				ElMessageBox.confirm(
						'此操作将永久删除?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.dele, {
								id: id
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '删除成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>

<style scoped>
</style>
