<template>
	<div>
		<el-dialog :draggable="true" width="60%" @close="Reset" :destroy-on-close="true" v-model="outerVisible"
			title="全部图片">
			<div class="dialog">
				<div class="img-list">
					<div v-for="(item,index) in ImgList" :key="index">
						<el-image class="dialog-img" :src="item" :preview-src-list="ImgList" :initial-index="index"
							fit="fill">
						</el-image>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	export default {
		data() {
			return {
				outerVisible: false,
				ImgList: []
			}
		},
		methods: {
			// 显示隐藏
			show(list) {
				this.ImgList = list
				this.outerVisible = true
			}
		}
	}
</script>

<style scoped lang="scss">
	.img-list {
		width: 100%;
		padding: 30px;
		min-height: 200px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		.dialog-img{
			margin-bottom: 15px;
		}
	}
</style>