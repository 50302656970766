import request from './request'

const api = {
	// 获取列表
	lists: '/activityenrool/lists',
	// 添加
	add: '/activityenrool/add',
	// 删除
	dele: '/activityenrool/del',
	// 编辑
	edit: '/activityenrool/update',
	// 详情
	info: '/activityenrool/read',
	// 增加积分
	incPoints: '/activityenrool/incPoints',
	// 减少积分
	decPoints: '/activityenrool/decPoints',
}

export {
	request,
	api
}
